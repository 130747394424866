<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header">
      <div class="card-title">
        <el-link type="primary" style="font-weight: bold; font-size: 19px" @click="$router.push({ name: 'notifi' })">お知らせメール一覧 </el-link>
        <i class="fas fa-angle-right breadcrumb-icon"></i>
        <span style="color: #66B0FE; font-weight: bold;">詳細</span>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-md-1">
          <span style="font-weight: bold">お知らせID:</span>
        </div>
        <div class="col-md-11 email-detail-content">
          <span >{{data.id}}</span>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-1">
          <span style="font-weight: bold">Mail to:</span>
        </div>
        <div class="col-md-11 email-detail-content ">
          <span >{{emailText}}</span>
        </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-1">
        <span style="font-weight: bold">件名:</span>
      </div>
      <div class="col-md-11 email-detail-content">
        <span >{{data.title}}</span>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-1">
        <span style="font-weight: bold">内容:</span>
      </div>
      <div class="col-md-11 email-detail-content"><pre class="content-email">{{data.content}}</pre></div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6 text-right content-email-btn">
        <el-button class="btn-cancel" @click="redirectNotifi()" >戻る</el-button>
      </div>
      <div class="col-md-6 content-email-btn">
        <el-button class="btn-send-email" @click="dialogDelete = true"  type="danger">削除</el-button>
      </div>
    </div>
      </div>
    <el-dialog
        title="本当に削除しますか？"
        :visible.sync="dialogDelete"
        width="30%"
        center
    >
        <span slot="footer" class="dialog-delete">
          <div class="row">
            <div class="col-md-6 text-right">
                  <el-button class="btn-cancel" @click="dialogDelete = false">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left">
                  <el-button type="primary" @click="comfirmDeleteNotifi()">はい</el-button>
            </div>
          </div>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { STATUS_CODE } from "@/core/config/constant";
import { DETAIL_EMAIL, DELETE_NOTIFI } from "@/core/services/store/notifi.module";
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "DetailEmail",
  data() {
    return {
      data: {},
      email:[],
      emailText:'',
      content:'',
      title:'',
      dialogDelete:false,
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    queryParam() {
      return this.$route.query;
    },
  },

  async created() {
    this.getDetailEmail();

  },

  methods: {
    async getDetailEmail() {
      this.loading = true;
      let id = this.$route.params['idNotifi'];
      let data = await this.$store.dispatch(DETAIL_EMAIL, id);
      if (data.code == STATUS_CODE.SUCCESS) {
        this.data = data.data
        let email = data.data.mail_to.split(',');

        for(let i = 0; i < 10; i++){
          if(email[i] !== null && email[i] !== '' && email[i] !== undefined){
            this.email.push(email[i])
          }
          else{
            break;
          }
        }
        if(email.length > 10){
          this.email.push(' ...')
        }
        this.emailText = this.email.join()
      }
      this.loading = false;
    },

   async comfirmDeleteNotifi(){
      const data = await this.$store.dispatch(DELETE_NOTIFI,{id: this.$route.params['idNotifi']} );
      if (data.code == STATUS_CODE.SUCCESS) {
        this.dialogDelete = false;
        this.$router.push({
          name: "notifi"});
        this.notifySuccess(this.$t("deleteSuccess"));
      }
    },
    redirectNotifi(){
      this.$router.push({ name: 'notifi'});
    }
  }
}

</script>
<style scoped>
.content-email-btn{
  margin-top: 30px;
}
.router-link{
  padding: 12px 20px;
  border: none;
  background-color: #409EFF;
  color: white;
  border-color: #409EFF;
}
.btn-cancel{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
  width: 120px;
}

.btn-send-email{
  width: 120px;
}
.breadcrumb-icon{
  font-size: 30px;
  padding: 0 5px;
}
.dialog-delete button{
  width: 120px;
}
::v-deep .el-dialog__header{
  padding-top: 50px;
}
.content-email{
  padding: 0px;
  background: none;
  border: none;
}
</style>
